<template>
  <div>
    <el-dialog
      class="custom-dialog pagetwo-dialog"
      :visible.sync="distrListDialogVisible"
    >
      <template v-slot:title>
        <h3>电站列表</h3>
      </template>
      <span class="u-font-38 blod u-p-l-36 white">电站列表</span>
      <div class="u-flex u-row-between u-p-20">
        <div class="u-flex">
          <el-select
            class="min-select"
            v-model="state"
            placeholder="请选择"
            @change="getEnergyList"
          >
            <el-option
              v-for="item in warnInfoOption1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <el-input
          style="width: 200px"
          v-model="params.searchKey"
          @change="getEnergyList"
          placeholder="请输入您想查找的电站名称"
        >
        </el-input>
        <!--      <div class="u-flex">
        <div class="btn btn-blue">单个导入</div>
        <div class="btn btn-blue u-m-l-20">批量导入</div>
      </div>-->
      </div>
      <el-table highlight-current-row :data="table" style="width: 100%">
        <el-table-column label="电站状态" prop="state">
          <template slot-scope="scope">
            <div style="display: flex; align-items: center">
              <i
                v-if="scope.row.state === 1"
                class="online_backgroundColor"
                style="margin-right: 5px"
              />
              <i
                v-if="scope.row.state === 2"
                class="offline_backgroundColor"
                style="margin-right: 5px"
              />
              <i
                v-if="scope.row.state === 3"
                class="fault_backgroundColor"
                style="margin-right: 5px"
              />
              <span style="margin-left: 10px">{{ scope.row.state_desc }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="电站名称" prop="name">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              :content="scope.row.name + scope.row.address"
              placement="top"
            >
              <div style="display: flex; flex-direction: column">
                <span
                  style="
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;
                    max-width: -webkit-calc(100% - 20px);
                    max-width: calc(100% - 20px);
                  "
                  >{{ scope.row.name }}</span
                >
                <div style="display: flex; align-items: center; color: #999">
                  <i class="iconfont icon-position" /><span
                    style="
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: inline-block;
                      max-width: -webkit-calc(100% - 20px);
                      max-width: calc(100% - 20px);
                    "
                    >{{ scope.row.address }}</span
                  >
                </div>
              </div>
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column label="当日电量" sortable="custom" prop="day_energy">
          <template slot-scope="scope">
            {{ scope.row.day_energy.value }}
            {{ scope.row.day_energy.unit }}
          </template>
        </el-table-column>
        <el-table-column label="累计电量" sortable prop="all_energy">
          <template slot-scope="scope">
            {{ scope.row.all_energy.value }}
            {{ scope.row.all_energy.unit }}
          </template>
        </el-table-column>
        <el-table-column label="满发小时数" sortable prop="full_hour">
          <template slot-scope="scope">
            {{ scope.row.full_hour.value }}
            {{ scope.row.full_hour.unit }}
          </template>
        </el-table-column>

        <el-table-column label="实时功率" sortable prop="power">
          <template slot-scope="scope">
            {{ scope.row.power.value }}
            {{ scope.row.power.unit }}
          </template>
        </el-table-column>
        <el-table-column label="组件总容量" sortable prop="capacity">
          <template slot-scope="scope">
            {{ scope.row.capacity.value }}
            {{ scope.row.capacity.unit }}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div class="u-flex btnbox u-row-center">
              <span
                @click="handleCurrentChange(scope.row)"
                style="cursor: pointer"
                >详情</span
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <el-pagination
        class="u-m-t-20 u-flex u-row-right u-p-r-60"
        layout="total,prev, pager, next,jumper,slot"
        :page-size="params.page_size"
        @current-change="changePage"
        :total="total"
      >
        <div class="btn" @click="clickEnter()">确定</div>
      </el-pagination>
      <div class="off" @click="distrListDialogVisible = false"></div>
    </el-dialog>
  </div>
</template>

<script>
import { getEnergyList } from "@/api/guangfu";
export default {
  name: "stationList",
  data() {
    return {
      distrListDialogVisible: false, //配电列表弹窗
      server_id: this.$store.state.screen.server_id,
      page: 1,
      table: [],
      total: 0,
      status: 1,
      state: "",
      tongji: {},
      last_page: 0,
      equipList: [],
      search: "", //搜索
      params: {
        loading: false,
        page: 1,
        page_size: 15,
        order_by: "desc",
        order_key: "created_at",
        searchKey: "",
      },
      warnInfoStatusOption: [
        {
          value: "",
          label: "状态",
        },
      ],
      warnInfoStatusValue: "all",
      warnInfoSnOption: [
        {
          value: "all",
          label: "全部",
        },
      ],

      warnInfoSnValue: "",
      warnInfoValue1: "",
      warnInfoOption1: [
        {
          value: "",
          label: "全部",
        },
        {
          value: 1,
          label: "正常",
        },
        {
          value: 2,
          label: "离线",
        },
        {
          value: 3,
          label: "报警",
        },
      ],
    };
  },
  created() {
    this.getEnergyList();
  },
  methods: {
    goPush() {
      this.distrListDialogVisible = true;
    },
    changePage(page) {
      this.params.page = page;
      this.getEnergyList();
    },
    getEnergyList() {
      getEnergyList({
        search_key: this.params.searchKey,
        page: this.params.page,
        state: this.state,
        page_size: this.params.page_size,
        order_by: this.params.order_by,
        order_key: this.params.order_key,
      }).then((res) => {
        this.table = res.data;
        this.total = res.meta.total;
        this.last_page = res.meta.last_page;
        this.tongji = res.tongji;
      });
    },

    // 分页器点击确定跳转
    clickEnter() {
      this.$emit("changeCurrentPage", this.page);
    },
    // eslint-disable-next-line no-unused-vars
    handleCurrentChange(currentRow) {
      // this.distrListDialogVisible = false;
      console.log("current", currentRow);
      this.$emit("change", currentRow.id);
    },
  },
};
</script>

<style scoped>
.offline_backgroundColor {
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  float: left;
  background-color: #ccc !important;
}
.online_backgroundColor {
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  float: left;
  background: #14dbff;
}

.fault_backgroundColor {
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  float: left;
  background-color: #d45559 !important;
}
</style>
