<template>
  <div class="public-panel panelh2 u-m-t-20">
    <h2>近一月发电</h2>
    <div class="chart" id="bar2"></div>
    <!-- <div class="chart" id="line1"></div> -->
  </div>
</template>

<script>
import tools from "@/unit/autotips";
import * as echarts from "echarts";
import { allstationReport } from "@/api/guangfu";
import { guanfuOption } from "@/echarts/guangfu";

export default {
  name: "WeekReport",
  data() {
    return {
      tool: [],
      insterval: this.$store.state.screen.time,
      timer: null,
    };
  },
  created() {
    this.allstationReport();
    this.timer = setInterval(() => {
      this.allstationReport();
    }, this.insterval);
  },
  methods: {
    async allstationReport() {
      const res = await allstationReport({ data_type: "lastMonth" });
      const data = res.data;

      this.$nextTick(() => {
        const myChart = echarts.init(document.getElementById("bar2"));
        const yxis = [];
        data.unit.forEach((item, index) => {
          console.log(index);
          yxis.push({
            type: "value",
            name: item,
            nameLocation: "end",
            scale: true,
            axisTick: {
              show: false,
            },

            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(0, 156, 249, 0.4)",
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                type: "dashed",
                color: "rgba(0, 156, 249, 0.3)",
              },
            },
            position: index % 2 == 0 ? "left" : "right",
            offset: parseInt(index / 2) * 20,
          });
        });
        const series = [];
        data.data.forEach((item, index) => {
          console.log(index);
          if (item.type === "bar") {
            series.push({
              name: item.name,
              type: item.type,
              data: item.data,
              barWidth: "20%",
              showBackground: true,
              backgroundStyle: {
                color: "rgba(255, 255, 255, 0.07)",
                borderColor: "rgba(0,121,120,1)",
                borderWidth: 0,
                borderRadius: 0,
                shadowColor: "rgba(0,121,120,0)",
                shadowBlur: 0,
              },
              label: {
                show: false,
                position: "top",
                textStyle: {
                  color: "#fff",
                  fontSize: 20,
                  fontWeight: 700,
                  fontFamily: "Oswald",
                },
              },
              itemStyle: {
                // barBorderRadius: 0,
                // borderColor: "#12A3FF",
                borderWidth: 1,
                barBorderRadius: 15,
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(0, 155, 255, 1)", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "rgba(0, 155, 255, 0)", // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            });
          } else {
            series.push({
              name: item.name,
              type: item.type,
              data: item.data,
              showSymbol: false,
              yAxisIndex: item.yAxisIndex,
              smooth: true,
              lineStyle: {
                color: "rgba(76, 187, 255, 1)",
              },
              itemStyle: {
                color: "rgba(76, 187, 255, 1)",
              },
            });
          }
        });
        guanfuOption.yAxis = yxis;
        guanfuOption.series = series;
        guanfuOption.legend.data = data.name;
        console.log("option", guanfuOption);
        myChart.setOption(guanfuOption, true);
        if (this.tool["bar2"] !== undefined) {
          this.tool["bar2"].clearLoop();
        }
        const tool = tools.loopShowTooltip(myChart, guanfuOption, {
          loopSeries: true,
        });
        this.tool["bar2"] = tool;
      });
    },
  },
};
</script>

<style scoped>
.chart {
}
.publicpage .panelh2 #bar2 {
  width: 100%;
  height: calc(100% - 0.9rem);
}
</style>
