<template>
  <div class="public-panel panelh2 u-m-t-20">
    <h2>节能减排</h2>
    <div class="u-flex loadbox u-m-t-10" style="margin-top: 0.5rem !important">
      <div class="item u-flex-1 u-flex u-flex-col">
        <div
          class="chartbox u-rela"
          style="
            display: flex;
            height: 2rem;
            justify-content: center;
            align-items: center;
          "
        >
          <img
            src="~@/assets/img/tanpaifang.png"
            style="width: 70%; height: auto"
          />
        </div>
        <div style="height: 0.8rem; display: flex; align-items: center">
          CO2减排
        </div>
        <h3 style="margin-top: 0" :class="onBj(30)">
          <span>{{ co2 }} t</span>
        </h3>
      </div>
      <div class="item u-flex-1 u-flex u-flex-col">
        <div
          class="chartbox u-rela"
          style="
            display: flex;
            height: 2rem;
            justify-content: center;
            align-items: center;
          "
        >
          <img
            src="~@/assets/img/dengxiaozhishu.png"
            style="width: 70%; height: auto"
          />
        </div>
        <div style="height: 0.8rem; display: flex; align-items: center">
          等效绿植
        </div>
        <h3 style="margin-top: 0" :class="onBj(30)">
          <span>{{ shu }} 棵</span>
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
import { dengxiao } from "@/api/guangfu";
export default {
  name: "Dengxiao",
  data() {
    return {
      co2: "",
      shu: "",
      insterval: this.$store.state.screen.time,
      timer: null,
    };
  },
  created() {
    this.dengxiao();
    this.timer = setInterval(() => {
      this.dengxiao();
    }, this.insterval);
  },
  methods: {
    async dengxiao() {
      const res = await dengxiao();
      this.co2 = res.data.co2;
      this.shu = res.data.shu;
    },
    //饼图占有率控制颜色
    onBj(num) {
      if (num < 40) {
        return "bg1";
      } else if (num >= 40 && num < 60) {
        return "bg2";
      } else {
        return "bg1";
      }
    },
  },
};
</script>

<style scoped></style>
